<template>
  <div class="splash-screen">
    <img
      src="../assets/amritsar_logo.png"
      class="logo animate__animated animate__fadeIn"
      v-if="$store.state.tdmId == '0001'"
    />
    <img
      src="../assets/amritsari_logo.png"
      class="logo animate__animated animate__fadeIn"
      v-else
    />
    <!-- <h1 class="app-name">Amritsari Haveli</h1> -->
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.splash-screen {
  position: absolute;
  display: block;
  align-items: center;
  text-align: center;
  height: 100vh;
  z-index: 9999;
  width: 100%;
  top: 0px;
  background-color: white;
}

.logo {
  position: relative;
  top: calc(50% - 100px);
  width: 180px;
  /* height: 100px; */
}
</style>
