import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueCryptojs from 'vue-cryptojs'
import ConfirmBox from "@/components/ConfirmBox.vue";
import MessageBox from "@/components/MessageBox.vue";

// axios.defaults.baseURL = "http://192.168.18.51:8180/ASRHAVELI/qr/src/php/";
axios.defaults.baseURL = "http://qr.amritsarhaveli.in/php/";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueCryptojs);
app.component("ConfirmBox", ConfirmBox);
app.component("MessageBox", MessageBox);
app.mount("#app");
