import { createStore } from 'vuex'

export default createStore({
  state: {
    currency: "₹",
    outletId: localStorage.getItem("outeltid"),
    tdmId: localStorage.getItem("tdmid"),
    tableNo: localStorage.getItem("tableno"),
    cart: [],
    sgstper: 2.5,
    cgstper: 2.5,
    igstper: 5,
    rzp_key: "rzp_test_lQhg9kpT36oRvE",
    rzp_secret: "9voxaNZ31BoyHwukwhx07pnr",
    netamount: 0,
    outletName: "",
    displayCurrency: "INR",
    cname: "Amritsar Haveli Groups",
    ordermain: {},
    appTopbar: true,
    appBackground: true,
    coupan: "Apply Coupan",
    disper: 0,
    dis: 0,
    coupanapplied: true
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
