<template>
  <div class="top-navbar animate__animated animate__slideInDown">
    <img
      src="../assets/amritsar_logo.png"
      class="logo"
      v-if="$store.state.tdmId == '0001'"
    />
    <img src="../assets/amritsari_logo.png" class="logo" v-else />

    <!-- <img
      alt="Amritsar Haveli logo"
      src="../assets/amritsar_logo.png"
      class="logo"
    /> -->
    <img
      alt="Amritsar Haveli logo"
      src="../assets/table.svg"
      class="table-logo"
    />

    <div class="tableno">{{ $store.state.tableNo }}</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      outletname: "Satvik Hospitality",
      tableno: this.$store.state.tableno,
      tdmId: "0001",
    };
  },
};
</script>

<style scoped>
.top-navbar {
  --nav-padding: 4px;
  position: fixed;
  top: 0px;
  padding: var(--nav-padding);
  width: calc(100% - var(--nav-padding));
  display: block;
  background-color: var(--light-bg);
  color: rgb(0, 0, 0);
  text-align: left;
  height: 65px;
  z-index: 99;
}

.logo {
  position: relative;
  justify-content: center;
  padding: 0px;
  width: 106px;
  display: inline-block;
  left: 10px;
  top: 5px;
}

.outlet-name {
  position: relative;
  color: var(--themesubmenu);
  font-weight: bolder;
  font-size: 18px;
}

.table-logo {
  position: absolute;
  padding: 0px;
  width: 100px;
  height: 53px;
  display: inline-block;
  right: 0px;
  top: 20px;
}
.tableno {
  position: absolute;
  color: var(--themesubmenu);
  text-align: center;
  font-size: 18px;
  font-weight: bolder;
  right: 40px;
  top: 15px;
}

@media only screen and (max-width: 1024px) {
  .top-navbar {
    height: 30px;
  }
  .logo {
    position: relative;
    justify-content: center;
    margin: 0px;
    padding: 0px;
    width: 50px;
  }

  .table-logo {
    width: 50px;
    height: 23px;
    display: inline-block;
    right: 0px;
    top: 13px;
  }
  .tableno {
    font-size: 14px;
    font-weight: bolder;
    right: 18px;
    top: 2px;
  }
}
@media only screen and (max-width: 600px) {
  .top-navbar {
    height: 30px;
  }
  .logo {
    position: relative;
    justify-content: center;
    margin: 0px;
    padding: 0px;
    width: 50px;
  }

  .table-logo {
    width: 50px;
    height: 23px;
    display: inline-block;
    right: 0px;
    top: 13px;
  }
  .tableno {
    font-size: 14px;
    font-weight: bolder;
    right: 18px;
    top: 2px;
  }
}
</style>
