import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue'),

  },
  {
    path: '/:qr',
    name: 'homeqr',
    component: () => import('../views/HomeView.vue'),
    props: true,
  },
  {
    path: '/invalid',
    name: 'invalid',
    component: () => import(/* webpackChunkName: "about" */ '../views/InValid.vue')
  },
  {
    path: '/invoice/:tc/:ser/:docno',
    name: 'invoice',
    component: () => import(/* webpackChunkName: "about" */ '../views/InvoiceView.vue'),
    props: true
  },
  {
    path: '/mailtest',
    name: 'mailtest',
    component: () => import(/* webpackChunkName: "about" */ '../views/MailTest.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
