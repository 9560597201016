<template>
  <div>
    <splash-screen v-if="showSplashScreen" />

    <div v-if="$store.state.appTopbar && showSplashScreen == false">
      <top-bar></top-bar>
    </div>
    <router-view v-show="showSplashScreen == false" />
  </div>
</template>
<script>
import SplashScreen from "@/components/SplashScreen.vue";

import TopBar from "./views/TopBar.vue";
export default {
  components: {
    TopBar,
    SplashScreen,
  },
  data() {
    return {
      showSplashScreen: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.showSplashScreen = false;
    }, 1000);
    // if (this.$store.state.appTopbar == true) {
    //   document.querySelector("body").classList.add("bodybg");
    // }
  },
};
</script>
<style>
@import "./css/base.css";
</style>
<style>
@import "./css/formcontrol.css";
</style>
<style>
html,
body {
  position: relative;
  padding: 0px;
  margin: 0px;
  clear: both;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: contain;
  background-position: center;
}
#app {
  text-align: center;
  width: 100%;
  height: fit-content;
  /* background: linear-gradient(to right, #434343 0%, black 100%); */
  /* background: linear-gradient(to right, #434343 0%, black 100%); */
  /* background: rgb(255, 181, 42);
  background: radial-gradient(
    circle,
    rgba(255, 181, 42, 1) 0%,
    rgba(0, 0, 0, 1) 97%
  ); */
}
/* .bodybg {
  background-color: rgb(255, 192, 42);
  background: radial-gradient(
    circle,
    rgba(255, 192, 42, 1) 0%,
    rgba(179, 41, 29, 1) 53%,
    rgba(0, 0, 0, 1) 97%
  );
} */
</style>
